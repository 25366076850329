<template>
  <div>
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-divider></v-divider>
    <general
      v-if="
        this.$route.name === 'new-campaign' ||
        this.$route.name === 'new-opera-campaign' ||
        this.$route.name === 'new-truecaller-campaign' ||
        this.$route.name === 'new-phoenix-campaign' ||
        this.$route.name === 'new-transsion-campaign' ||
        this.$route.name === 'new-ctv-campaign' ||
        this.$route.name === 'new-gaming-campaign'
      "
      class="mt-5"
      @emitCampaignBodyCreate="createCampaign($event)"
      @getLocationName="getLocation($event)"
      :google_target_locations="google_target_locations"
    />
  </div>
</template>

<script>
import General from "../../components/NewCampaign/General.vue";

export default {
  data: () => ({
    currentItem: "general",
    error: null,
    items: [
      {
        text: "Campaigns",
        disabled: false,
        href: "#/tradedesk/campaigns",
      },
      {
        text: "Create Campaign",
        disabled: true,
        href: "#/tradedesk/create-campaign",
      },
    ],
    google_target_locations: [],
    geo_id: [],
  }),
  components: {
    General,
  },

  methods: {
    //Create Camapign
    async createCampaign(req_body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/createDspCampaigns", req_body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Campaign created successfully!",
          group: "success",
        });

        if (this.dsp_campaign) {
          await this.$router.push({
            name: "add-programmatic-banner",
            query: {
              campaignId: this.dsp_campaign.id,
            },
          });
          // await this.$router.push("/tradedesk/campaigns");
        }
      } catch (error) {
        this.$notify({
          title: error.message,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async getLocation(id) {
      this.geo_id = id;
      let geo_object = {
        id: id,
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/includedLocationbyId", geo_object);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
  computed: {
    dsp_campaign() {
      return this.$store.state.dsp.dsp_campaign;
    },
    included_geo_locations_by_id() {
      return this.$store.state.dsp.included_geo_locations_by_id.flat();
    },
  },
  watch: {
    included_geo_locations_by_id() {
      for (let i = 0; i < this.geo_id.length; i++) {
        let location = this.included_geo_locations_by_id.filter(
          (location) => location.geo_id === parseInt(this.geo_id[i])
        );
        this.google_target_locations.push(location[0].location_name);
      }
    },
  },
};
</script>
