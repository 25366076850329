var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.items}}),_c('v-divider'),(
      this.$route.name === 'new-campaign' ||
      this.$route.name === 'new-opera-campaign' ||
      this.$route.name === 'new-truecaller-campaign' ||
      this.$route.name === 'new-phoenix-campaign' ||
      this.$route.name === 'new-transsion-campaign' ||
      this.$route.name === 'new-ctv-campaign' ||
      this.$route.name === 'new-gaming-campaign'
    )?_c('general',{staticClass:"mt-5",attrs:{"google_target_locations":_vm.google_target_locations},on:{"emitCampaignBodyCreate":function($event){return _vm.createCampaign($event)},"getLocationName":function($event){return _vm.getLocation($event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }